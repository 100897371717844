import { questionType } from '@/api';

// Parses an admin service JWT. Correctly decodes the JWT using base64Url.
// https://stackoverflow.com/a/38552302/358873
// This returns the 'user' claim of the token only.
export function parseToken(token) {
  if (!token) {
    return null;
  }

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload)?.user;
}

export function isHeightQuestion(question) {
  return (
    question.type === questionType.number &&
    // 4 is a fallback value for older configurations that don't have the special type configured
    (question.id === 4 || question.special_type === 'height-cm')
  );
}

export function isWeightQuestion(question) {
  return (
    question.type === questionType.number &&
    // 5 is a fallback value for older configurations that don't have the special type configured
    (question.id === 5 || question.special_type === 'weight-kg')
  );
}

// Parses the current patient JWT in session storage and returns the `user` claim.
// Returns `null` if no token is present.
export function getCurrentUser() {
  return parseToken(sessionStorage.getItem('token'));
}

// Parses the current admin JWT in session storage and returns the `user` claim.
// Returns `null` if no token is present.
export function getCurrentAdmin() {
  return parseToken(sessionStorage.getItem('adminToken'));
}
