<template>
  <div class="landing-page">
    <div class="login ml-auto mr-auto">
      <div class="card" title="Login">
        <div class="card-header">
          <div class="flex flex-between align-center">
            <img src="@/assets/Logo_EPOS.png" class="logo" alt="E+POS Logo" />
            <div class="flex flex-col" v-if="user">
              <span class="tenant-name">{{ user.tenant }}</span>
              <span>{{ user.first_name }} {{ user.last_name }}</span>
            </div>
          </div>
        </div>
        <div class="card-body">
          <template v-if="!user">
            <form @submit.prevent="credentialsLogin" autocomplete="off">
              <div class="form-group">
                <label for="email">{{ ta('email.label') }}</label>
                <input
                  type="email"
                  v-model="email"
                  class="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  :placeholder="ta('email.placeholder')"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label for="password">{{ ta('password.label') }}</label>
                <input
                  v-model="password"
                  type="password"
                  class="form-control"
                  id="password"
                  :placeholder="ta('password.placeholder')"
                  autocomplete="off"
                />
              </div>
              <div class="alert alert-danger" v-if="warning">
                {{ warning }}
              </div>
              <button type="submit" class="btn btn-novacair-light">
                {{ ta('login.label') }}
              </button>
            </form>
          </template>

          <template v-if="user && shouldVerify">
            <form @submit.prevent="twoFactorLogin" autocomplete="off">
              <div class="alert alert-info">
                <template v-if="user.authenticator_enabled">
                  {{ ta('enter_authenticator_code') }}
                </template>
                <template v-else>
                  {{
                    ta('enter_sms_code', {
                      mobile: user.mobile,
                    })
                  }}
                </template>
              </div>
              <div class="form-group">
                <input
                  required
                  type="text"
                  v-model="twoFactorCode"
                  class="form-control"
                  :placeholder="
                    user.authenticator_enabled
                      ? ta('authenticator_code.placeholder')
                      : ta('sms_code.placeholder')
                  "
                  autocomplete="off"
                />
              </div>
              <div class="alert alert-danger" v-if="warning">
                {{ warning }}
              </div>
              <div class="buttons">
                <button type="submit" class="btn btn-novacair-light">
                  {{ ta('continue') }}
                </button>
                <button
                  class="btn btn-novacair-invisible"
                  @click.stop.prevent="logout"
                >
                  {{ ta('logout') }}
                </button>
              </div>
            </form>
          </template>

          <template v-else-if="authenticated">
            <form @submit.prevent="passcodeLogin" autocomplete="off">
              <div class="alert alert-info">
                {{ ta('passcode.enter') }}
              </div>
              <div class="form-group">
                <input
                  required
                  type="text"
                  v-model="passcode"
                  class="form-control"
                  id="pass-code"
                  aria-describedby="emailHelp"
                  :placeholder="ta('passcode.placeholder')"
                  autocomplete="off"
                />
              </div>
              <div class="alert alert-danger" v-if="warning">
                {{ warning }}
              </div>
              <div class="buttons">
                <button type="submit" class="btn btn-novacair-light">
                  {{ ta('continue') }}
                </button>
                <button
                  class="btn btn-novacair-invisible"
                  @click.stop.prevent="logout"
                >
                  {{ ta('logout') }}
                </button>
              </div>
            </form>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import {
  getCurrentUser,
  loginCredentials,
  loginTwoFactor,
  openScreeningByPasscode,
} from '@/api';
import { isEmpty } from 'lodash';
import { useRouter } from 'vue-router/composables';
import { getCurrentAdmin, parseToken } from '@/lib/functions';
import { useHeadfulSetter } from '@/lib/hooks';
import i18n, { adminI18n } from '@/i18n';

const router = useRouter();

// Shortcut for admin translations
function ta(key, values) {
  return adminI18n.t(key, values);
}

const setHeadful = useHeadfulSetter();
setHeadful({
  title: 'E+POS - Login',
  description: ta('title'),
});

// Currently logged in user (decoded token)
const user = ref(null);
const authenticated = computed(() => !!user.value);

// Whether 2FA login is required for the current user
const shouldVerify = computed(() => !!user.value?.two_factor_login_required);

// Currently entered credentials
const email = ref('');
const password = ref('');
const twoFactorCode = ref('');

// Warning message
const warning = ref(null);

// Currently entered pass code
const passcode = ref('');

// Resets all reactive variables to their original values
function resetState(loginData) {
  user.value = null;
  warning.value = null;

  if (loginData) {
    email.value = '';
    password.value = '';
    twoFactorCode.value = '';
  }

  passcode.value = '';
}

function clearTokens() {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('adminToken');
}

// Removes any login tokens and resets the client state
function logout() {
  clearTokens();

  const origin = sessionStorage.getItem('tokenOrigin');
  if (origin) {
    sessionStorage.removeItem('tokenOrigin');
    window.location.href = origin;
  }

  resetState(true);
}

// Processes request errors
function processError(errors) {
  if (errors.response.status === 401) {
    clearTokens();

    // Reset state but keep the filled out login fields
    resetState(false);
  }

  processErrorMessages(errors.response);
}

// Processes response error messages into a warning
function processErrorMessages(errors) {
  warning.value = null;

  if (errors.data && errors.data.message) {
    if (!isEmpty(errors.data.errors)) {
      // Join any errors.
      warning.value = Object.values(errors.data.errors).join('\n');
    } else {
      warning.value = errors.data.message;
    }
  } else {
    warning.value = ta('failed');
  }
}

// Processes a login response
function processLogin(response) {
  resetState(true);
  const token = response.data;
  sessionStorage.setItem('adminToken', token);
  user.value = getCurrentAdmin();

  if (
    authenticated.value &&
    !shouldVerify.value &&
    (user.value.all_tenants?.length ?? 0) > 1
  ) {
    // Show the tenant switcher
    router.replace({ name: 'selectTenant' });
  }
}

async function twoFactorLogin() {
  if (!twoFactorCode.value) {
    return;
  }

  try {
    const response = await loginTwoFactor(twoFactorCode.value, i18n.locale);
    processLogin(response);
  } catch (e) {
    processError(e);
  }
}

async function credentialsLogin() {
  try {
    const response = await loginCredentials(
      email.value,
      password.value,
      adminI18n.locale
    );
    processLogin(response);
  } catch (e) {
    processError(e);
  }
}

async function passcodeLogin() {
  try {
    const response = await openScreeningByPasscode(passcode.value);
    warning.value = null;
    passcode.value = null;

    const token = response.data;
    sessionStorage.setItem('token', token);
    user.value = parseToken(token);

    if (user.value?.screening_id) {
      await router.replace('/');
    }
  } catch (e) {
    processError(e);
  }
}

onMounted(async () => {
  user.value = getCurrentAdmin();

  // Not logged in or 2FA required
  if (!user.value || shouldVerify.value) {
    return;
  }

  // Fetch current user. This checks if the current token is valid.
  // (I honestly don't know if this is necessary, but this was present
  // and I'm leaving it in for historical purposes).
  try {
    await getCurrentUser();
  } catch (e) {
    processError(e);
  }
});
</script>

<style lang="scss" scoped>
.login {
  padding-top: 60px;
  max-width: 600px;
}

.logo {
  height: 30px;
  margin: 25px 0 20px 20px;
}

.tenant-name {
  font-size: 1.5rem;
  font-weight: 400;
}

.btn-novacair-light {
  border: 1px solid $primary;
  background-color: white;
  color: black;
  padding: 5px 30px;
}

.alert {
  margin-top: 20px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
