<template>
  <div class="button-row" :class="$mq">
    <button
      type="button"
      class="btn btn-novacair-selected nextbutton"
      @click="downloadQuestionnaire"
      :disabled="loading"
    >
      <i v-if="loading" class="fas fa-spinner fa-pulse"></i>
      <i v-if="!loading" class="fas fa-file-pdf"></i
      ><span v-if="!loading">{{ $t('download') }}</span>
    </button>
  </div>
</template>

<script>
import { downloadQuestionnaire } from '@/api';
import { getCurrentUser } from '@/lib/functions';

export default {
  name: 'DownloadQuestionnaireQuestionType',
  props: ['isSmall', 'screening'],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    downloadQuestionnaire() {
      this.loading = true;

      const user = getCurrentUser();
      downloadQuestionnaire(user).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.button-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 2rem;

  &.small {
    padding-bottom: 3rem;
  }

  .nextbutton {
    * + * {
      padding-left: 0.5rem;
    }

    font-weight: 600;
    width: 250px;
    font-weight: 500;
  }
}
</style>
