import { render, staticRenderFns } from "./TenantSelector.vue?vue&type=template&id=e1059146&scoped=true"
import script from "./TenantSelector.vue?vue&type=script&setup=true&lang=js"
export * from "./TenantSelector.vue?vue&type=script&setup=true&lang=js"
import style0 from "./TenantSelector.vue?vue&type=style&index=0&id=e1059146&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1059146",
  null
  
)

export default component.exports