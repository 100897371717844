<template>
  <div class="w-100">
    <table :class="$mq" class="medication-table w-100 is-max-widescreen">
      <thead>
        <MedicationTableHeader />
      </thead>
      <tbody>
        <template v-for="(row, key) in rows">
          <MedicationTableRow
            :key="key + '_' + updateNr"
            :row="row"
            :setAnswer="saveAnswer"
            :onTypeInput="onInput"
          />
        </template>
      </tbody>
    </table>
    <div v-if="hasIncompleteAnswer" class="validation-message">
      {{ $t('medication.missing_name') }}
    </div>
  </div>
</template>

<script>
import MedicationTableRow from './MedicationTableRow';
import MedicationTableHeader from './MedicationTableHeader';
import languages from '@/languages';

export default {
  components: { MedicationTableHeader, MedicationTableRow },
  props: ['question', 'selectedOptions', 'bloodthinnerQuestion'],
  data() {
    return {
      rows: [],
      updateNr: 1,
    };
  },
  created() {
    this.initTable();
  },
  watch: {
    selectedOptions() {
      this.initTable();
      this.saveAnswer();
    },
  },
  computed: {
    hasIncompleteAnswer() {
      return (
        this.question.answer &&
        this.question.answer.find(
          (row) => !row.name && !row.option && (row.dose || row.frequency)
        )
      );
    },
  },
  methods: {
    selectedBloodthinnerOptions() {
      return this.selectedOptions.options.filter(
        (option) =>
          !['unknown', 'other'].includes(option.type) &&
          this.bloodthinnerQuestion.answer.find(
            (answer) => answer === option.id
          )
      );
    },
    saveAnswer() {
      if (!this.hasIncompleteAnswer) {
        this.$emit('setAnswer', {
          question: this.question,
          answer: this.rows
            .filter((row) => row.name || row.option)
            .map(this.transformRowToAnswer),
        });
      }
    },
    transformRowToAnswer(row) {
      return {
        question_option_id: row.option ? row.option.id : null,
        name: row.name,
        dose: row.dose,
        frequency: row.frequency,
      };
    },
    initTable() {
      this.rows = [
        ...this.selectedOptions.map(this.createRowFromSelectedOption),
        ...(this.question.answer || [])
          .filter((answer) => !answer.question_option_id)
          .map(this.createRowFromAnswer),
      ];

      this.fixEmptyRows();
      this.updateNr++;
    },
    onInput() {
      // update this.question.answer directly on keystroke, to recalculate isQuestionAnswered in Questions component
      // eslint-disable-next-line vue/no-mutating-props
      this.question.answer = this.rows.filter((row) => !this.rowIsEmpty(row));

      this.fixEmptyRows();
    },
    createRowFromSelectedOption(option) {
      const answer = (this.question.answer || []).find(
        (answer) => answer.question_option_id === option.id
      );
      return {
        name: languages.translate('nl', option, 'text'),
        dose: answer ? answer.dose : null,
        frequency: answer ? answer.frequency : null,
        option: option,
      };
    },
    createRowFromAnswer(answer) {
      return {
        name: answer.name,
        dose: answer.dose,
        frequency: answer.frequency,
      };
    },
    createEmptyRow() {
      return {
        name: null,
        dose: null,
        frequency: null,
      };
    },
    fixEmptyRows() {
      //we want 3 empty rows initially, excluding fixed rows, and when they are used, always have one empty row
      const totalRowCount = this.rows.length;
      const fixedRowCount = this.rows.filter((row) => !!row.option).length;
      const emptyRowCount = this.emptyTrailingRowsCount();
      const minimumRowCount = fixedRowCount + 3;

      if (totalRowCount < minimumRowCount) {
        this.appendEmptyRows(minimumRowCount - totalRowCount);
      } else if (emptyRowCount === 0) {
        this.appendEmptyRows(1);
      } else if (totalRowCount > minimumRowCount && emptyRowCount > 1) {
        this.removeEmptyRows(
          Math.min(totalRowCount - minimumRowCount, emptyRowCount - 1)
        );
      }
    },
    emptyTrailingRowsCount() {
      let count = 0;
      this.rows.forEach((row) => {
        if (this.rowIsEmpty(row)) {
          count++;
        } else {
          count = 0;
        }
      });
      return count;
    },
    rowIsEmpty(row) {
      return (
        !row ||
        (!row.question_option_id && !row.name && !row.dose && !row.frequency)
      );
    },
    appendEmptyRows(nRows = 1) {
      for (let i = 0; i < nRows; i++) {
        this.rows.push(this.createEmptyRow());
      }
    },
    removeEmptyRows(nRows = 1) {
      for (let i = 0; i < nRows; i++) {
        this.rows.pop();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.medication-table.small thead {
  display: none;
}
.validation-message {
  padding: 5px 0 0 5px;
  color: red;
}
</style>
