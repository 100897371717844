<template>
  <tr class="medication-table-row" :class="$mq">
    <td class="table-cell">
      <label>{{ $t('medication.medication_name') }}</label>
      <input
        v-if="row.option"
        :disabled="true"
        @click.stop="() => false"
        class="form-control disabled input"
        v-model="translatedName"
      />
      <input
        v-else
        :disabled="false"
        :placeholder="$t('medication.medication_name_placeholder')"
        @click.stop="() => false"
        @input="onTypeInput"
        @blur="setAnswer"
        class="form-control input"
        v-model="mutableRow.name"
        maxlength="255"
      />
    </td>

    <td class="table-cell">
      <label>{{ $t('medication.dose') }}</label>
      <input
        :placeholder="$t('medication.dose_placeholder')"
        @click.stop="() => false"
        @input="onTypeInput"
        @blur="setAnswer"
        class="form-control input"
        v-model="mutableRow.dose"
        maxlength="128"
      />
    </td>

    <td class="table-cell">
      <label>{{ $t('medication.frequency') }}</label>
      <input
        :placeholder="$t('medication.frequency_placeholder')"
        @click.stop="() => false"
        @input="onTypeInput"
        @blur="setAnswer"
        class="form-control input"
        v-model="mutableRow.frequency"
        maxlength="32"
      />
    </td>
  </tr>
</template>

<script>
import languages from '@/languages';

export default {
  props: ['row', 'setAnswer', 'onTypeInput'],
  computed: {
    mutableRow() {
      // Wrapper to suppress the prop mutation error, which is harmless here.
      return this.row;
    },
    translatedName() {
      if (this.row.option) {
        return languages.translate(this.$i18n.locale, this.row.option, 'text');
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
@supports (-webkit-touch-callout: none) {
  input:disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 1);
    opacity: 1;
  }
}

tr {
  td {
    padding: 0.2em;

    label {
      display: none;
    }

    .input {
      padding: 0.5em;
      font-weight: 600;

      &.input::placeholder {
        font-weight: 400;
      }
    }
  }

  &.small {
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    flex-wrap: wrap;

    td {
      width: 50%;
      &:first-of-type {
        width: 100%;
      }
    }

    label {
      display: block;
      font-size: 1.4em;
      font-weight: 700;
      margin-bottom: 0;
      margin-top: 0.3rem;
    }
  }
}
</style>
