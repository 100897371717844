<template>
  <div class="fontresizer flex flex-between" :class="dark ? 'dark' : 'light'">
    <div class="explanation">{{ $t('font_label') }}</div>
    <button
      v-for="candidate in fontSizes"
      :key="candidate"
      class="btn btn-invisible"
      :class="candidate + (fontSize === candidate ? ' active' : '')"
      @click.stop.prevent="setFontSize(candidate)"
    >
      A
    </button>
  </div>
</template>

<script>
export default {
  props: ['fontSize', 'dark'],
  data() {
    return {
      fontSizes: ['m', 'l', 'xl'],
    };
  },
  methods: {
    setFontSize(fontSize) {
      this.$emit('setFontSize', fontSize);
    },
  },
};
</script>

<style scoped lang="scss">
.fontresizer.dark {
  margin: 4px 0;

  .explanation {
    color: $primary;
  }

  .btn {
    color: $primary;
  }
}

.fontresizer.light {
  margin-top: 1px;
  margin-right: 20px;

  .explanation {
    margin-top: 1px;
  }
}

.fontresizer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .explanation {
    line-height: 35px;
    font-size: 15px;
    width: 105px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .btn {
    /*width: 15px;*/
    line-height: 35px;
    /*height: 35px;*/
    color: white;
    outline: none;
    box-shadow: none;
    padding: 0;
  }

  .btn.active {
    opacity: 1;
    font-weight: 900;
  }
  .btn:not(.active) {
    opacity: 0.45;
    font-weight: 200;
  }

  .m {
    font-size: 15px;
  }
  .l {
    font-size: 18px;
  }
  .xl {
    font-size: 20px;
  }
}
</style>
