import Vue from 'vue';
import Router, { NavigationFailureType } from 'vue-router';

Vue.use(Router);

// Ignore duplicate navigation errors.
const originalPush = Router.prototype.push;
Router.prototype.push = function (location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.type !== NavigationFailureType.duplicated) {
      throw err;
    }
  });
};

export default new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE,
  routes: [
    {
      path: '',
      name: 'start',
      redirect: 'continue',
    },
    {
      path: '/clinic',
      alias: '/poli',
      name: 'loginLocal',
      component: () => import('@/components/LoginLocal.vue'),
    },
    {
      path: '/clinic-gateway',
      name: 'clinicGateway',
      component: () => import('@/components/ClinicGateway.vue'),
    },
    {
      path: '/select-environment',
      name: 'selectTenant',
      component: () => import('@/components/TenantSelector.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/components/Login.vue'),
    },
    {
      path: '/:section',
      name: 'screening',
      component: () => import('@/components/Screening.vue'),
      beforeEnter: (to, from, next) => {
        if (!sessionStorage.getItem('token')) {
          if (sessionStorage.getItem('adminToken')) {
            next('/clinic');
          } else {
            next('/login');
          }
        }

        next();
      },
    },
  ],
});
