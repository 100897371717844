<template>
  <tr>
    <td class="table-cell">
      {{ $t('medication.medication_name') }}
    </td>
    <td class="table-cell">
      {{ $t('medication.dose') }}
    </td>
    <td class="table-cell">
      {{ $t('medication.frequency') }}
    </td>
  </tr>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.table-cell {
  font-size: 1.1em;
  font-weight: 700;
}
</style>
