import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Contains I18n definitions and utility functions. Note that we have two sets of i18n messages:
// admin and client. These use different instances because their sets of supported languages
// are different, and they might even have a different language configured at different times
// (e.g. the user might fill out their questionnaire in Arabic while the admin helping them
//  might still be logged in in English).
// The regular patient client i18n will be made available under the global internationalization
// scope (the $t() family of functions can be used), the admin i18n you'll have to include and
// call manually.

Vue.use(VueI18n);

//#region Shared functionality
function loadLocaleMessages(locales) {
  const messages = {};

  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages;
}

function _configureLocale(
  queryLocale,
  sessionKey,
  i18n,
  updateDocument = false
) {
  // Find a locale in this order:
  // - Passed as a query string
  // - Present in session storage
  // - Browser language
  // This locale is then passed to VueI18n, which will automatically
  // fall back to any available dialect (e.g. `en-US` will fall back
  // to `en`). If no dialect is available, it falls back to the
  // configured fallbackLocale.
  const locale =
    queryLocale ||
    sessionStorage.getItem(sessionKey) ||
    (navigator.languages && navigator.languages[0]) ||
    navigator.language;

  if (locale && i18n.locale !== locale) {
    i18n.locale = locale;
  }

  if (updateDocument) {
    // Set the html lang / dir attributes.
    const activeLocale = guessActiveLocale(locale ?? i18n.fallbackLocale);

    // Set the estimated html lang attribute
    document.documentElement.lang = activeLocale;
    document.documentElement.dir = activeLocale === 'ar' ? 'rtl' : 'ltr';
  }

  // If the locale was set in the query string, persist
  // it to session storage.
  if (locale && queryLocale) {
    sessionStorage.setItem(sessionKey, queryLocale);
  }
}
//#endregion

//#region Regular i18n
// Note: doesn't take variables, expanded to actual values at compile time.
const locales = require.context(
  './assets/locales',
  true,
  /[A-Za-z0-9-_,\s]+\.json$/i
);

// Returns the fallback locale based on the given domain. This is the
// locale that will be used if none of the messages or message dialects
// match the requested locale.
export function getFallbackLocale() {
  return window.location.hostname.endsWith('.ch') ? 'de' : 'nl';
}

const allMessages = loadLocaleMessages(locales);
export const availableLocales = Object.keys(allMessages);

// General i18n for the patient client
const i18n = new VueI18n({
  // Will be overwritten the first time it is configured
  locale: getFallbackLocale(),
  fallbackLocale: getFallbackLocale(),
  messages: allMessages,
  silentFallbackWarn: true,
});

// Attempts to set the given locale as the session and client locale. Sets the
// best matching locale if it doesn't match any known locales.
export function configureLocale(queryLocale) {
  _configureLocale(queryLocale, 'locale', i18n, true);
}

export default i18n;
//#endregion

//#region Admin i18n
const adminLocales = require.context(
  './assets/admin-locales',
  true,
  /[A-Za-z0-9-_,\s]+\.json$/i
);
export const adminMessages = loadLocaleMessages(adminLocales);

export function getAdminFallbackLocale() {
  return window.location.hostname.endsWith('.ch') ? 'en' : 'nl';
}

// Specific i18n for the admin client
export const adminI18n = new VueI18n({
  // Will be overwritten the first time it is configured
  locale: getAdminFallbackLocale(),
  fallbackLocale: getAdminFallbackLocale(),
  messages: adminMessages,
  silentFallbackWarn: true,
});

export function configureAdminLocale(queryLocale) {
  _configureLocale(queryLocale, 'adminLocale', adminI18n);
}
//#endregion

// Makes a best effort guess what locale is currently used for
// translations by VueI18n, so we can set it as the html[lang] attribute.
// Pass in the locale requested by the client, which can either be
// the navigator language or the configured tenant locale.
export function guessActiveLocale(locale) {
  // If the locale is present verbatim in our locale map,
  // that's the one we're using.
  if (availableLocales.includes(locale)) {
    return locale;
  }

  // If a generic locale variant is present in our map,
  // most likely that is what's being used.
  const generic = locale.split('-', 1)[0];
  if (generic !== locale && availableLocales.includes(generic)) {
    return generic;
  }

  // If the main locale and generic locale aren't present, the fallback
  // locale is most likely being used. A possible exception would occur
  // if, for instance, we register en-GB as a locale, but not `en`.
  // VueI18n might fall back to `en-GB` for `en-US` in that case. We should
  // make sure to always register the most common specific variant as the
  // generic variant for this reason.
  return i18n.fallbackLocale.toString();
}
