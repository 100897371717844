<template>
  <div class="anesthesia-choice">
    <div class="row">
      <div class="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-12 questioncol">
        <label class="control-label">
          <span v-html="questionText"></span>
          <template v-if="preferredTechnique">
            <br />
            <span class="preference"
              >{{ $t('anesthesia-preference') }}
              {{ translate(preferredTechnique, 'title') }}</span
            >
          </template>

          <span v-if="questionExplanation" class="infobutton-wrapper">
            <i
              class="fal fa-info-circle fa-2x infobutton"
              @click="toggleModal(question)"
            ></i>
          </span>
        </label>
      </div>
      <div
        v-if="applicableTechniques"
        class="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-12 answercol wide"
      >
        <div
          class="optionpointer vertical"
          v-for="technique in sortedTechniques"
          :key="technique.value"
        >
          <button
            class="btn answer"
            @click="onClick(technique.value)"
            :class="
              technique.value === answer
                ? 'btn-novacair-selected'
                : 'btn-novacair-light'
            "
            :disabled="!enabled"
          >
            <span class="techniquename">{{
              translate(technique, 'title')
            }}</span>
            <span
              class="preferredoption"
              v-if="technique === preferredTechnique"
            >
              {{ $t('anesthesia-preference-option') }}
            </span>
            <span class="techniquedescription">
              {{ translate(technique, 'description') }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import languages from '@/languages';
import i18n from '@/i18n';

const props = defineProps({
  applicableTechniques: Array,
  preferredTechnique: Object,
  questionText: String,
  enabled: Boolean,
  questionExplanation: String,
  answer: String,
});

const emit = defineEmits(['toggleModal', 'onClick']);

function toggleModal(question) {
  emit('toggleModal', question);
}

function onClick(option) {
  emit('onClick', option);
}

function translate(item, key) {
  return languages.translate(i18n.locale, item, key);
}

const sortedTechniques = computed(() => {
  if (!props.preferredTechnique) {
    return props.applicableTechniques;
  }

  const selected = props.applicableTechniques.find(
    (t) => t === props.preferredTechnique
  );
  const withoutSelected = props.applicableTechniques.filter(
    (t) => t !== props.preferredTechnique
  );
  return selected ? [selected, ...withoutSelected] : props.applicableTechniques;
});
</script>

<style scoped lang="scss">
.answercol {
  display: flex;
  flex-direction: column;
}

.answer {
  width: 100%;
  margin-bottom: 10px;
  white-space: normal;
  display: inline-block;
}

.techniquename,
.preferredoption,
.techniquedescription {
  display: block;
}

.techniquename {
  padding-top: 10px;
  font-weight: 700;
}

.techniquedescription {
  padding: 5px 0 10px 0;
  opacity: 0.8;
}

.preference {
  font-weight: 600;
  line-height: 40px;
}

.preferredoption {
  font-weight: 600;
  padding: 12px 0 10px 0;
}
</style>
