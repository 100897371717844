import { datadogLogs } from '@datadog/browser-logs';
import { getCurrentAdmin, getCurrentUser } from '@/lib/functions';

datadogLogs.init({
  clientToken: process.env.VUE_APP_DATADOG_CLIENT_KEY ?? '',
  site: 'datadoghq.eu',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  beforeSend: function (log) {
    const patient = getCurrentUser();
    if (patient) {
      log.patient_user = {
        patient_id: patient.id,
        tenant_id: patient.tenant_id,
        screening_id: patient.screening_id,
      };
    }

    const admin = getCurrentAdmin();
    if (admin) {
      log.admin_user = {
        id: admin.id,
        tenant_id: admin.tenant_id,
      };
    }

    return true;
  },
});

datadogLogs.setGlobalContextProperty('service', 'patient-client');
datadogLogs.setGlobalContextProperty('env', process.env.NODE_ENV);
