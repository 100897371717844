<template>
  <div class="video-container">
    <video :poster="videoPoster" controls controlslist="nodownload">
      <source :src="videoUrl" type="video/mp4" />
    </video>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import i18n from '@/i18n';
import { getCurrentUser } from '@/lib/functions';

const props = defineProps({
  video: String,
});

const storageUrl = process.env.VUE_APP_NOVACAIR_STORAGE;

const versionPath = computed(() => {
  const patient = getCurrentUser();

  // If the tenant specifies a video handle, we
  // serve the videos from its subpath.
  return patient.video_handle ? `${patient.video_handle}/` : '';
});

// Narcose is an alias for 'full' (apparently, source lost)
const videoName = computed(() =>
  props.video === 'narcose' ? 'full' : props.video
);

const nlOnly = ['staaroperatie'];

const urlBase = computed(() => {
  const name = videoName.value;
  return nlOnly.includes(videoName.value)
    ? `${storageUrl}/videos/nl/${name}`
    : `${storageUrl}/videos/${versionPath.value}${i18n.locale}/${name}`;
});

const videoPoster = computed(() => urlBase.value + '.png');
const videoUrl = computed(() => urlBase.value + '.mp4');
</script>

<style scoped lang="scss">
video {
  // Match the container div
  width: 100%;
}
</style>
