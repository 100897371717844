<template>
  <div id="wrapper" class="wrapper" :class="{ large: $mq === 'large' }">
    <div
      class="scroll-up-button"
      v-if="scrollStatus === 'up'"
      @click="scroll"
      title="Naar de volgende vraag"
    >
      <i class="far fa-angle-double-up fa-2x white"></i>
    </div>
    <div
      class="scroll-down-button"
      v-if="scrollStatus === 'down'"
      @click="scroll"
      title="Naar de volgende vraag"
    >
      <i class="far fa-angle-double-down fa-2x white"></i>
    </div>

    <div
      id="scroller"
      class="scroller"
      :class="{ large: $mq === 'large' }"
      v-on:scroll="onScroll"
    >
      <div class="questions" :class="sectionBackgroundColor(section)">
        <template v-if="!loadingSection">
          <div v-for="(question, index) in visibleQuestions" :key="index">
            <hr class="questiondivider" v-if="index > 0" />
            <question
              :isLocal="isLocal"
              :screening="screening"
              @setAnswer="setAnswer"
              @toggleModal="toggleModal"
              :key="question.id"
              :question="question"
              :isLanguageRtl="isLanguageRtl"
              :id="'question-' + question.id"
              :enabled="!isQuestionFuture(question)"
              :visibleQuestions="visibleQuestions"
              :closeScreening="closeScreening"
            />
          </div>
          <div v-if="sectionButtonPosition(section) === 'page'" class="row">
            <div class="col-12 buttoncol">
              <button
                type="button"
                class="btn btn-novacair-selected nextbutton"
                :class="{ 'nextbutton-mobile': isSmall }"
                @click.stop.prevent="next"
                :disabled="!isFinished"
              >
                <template v-if="!loadingSection">{{
                  sectionButtonText(section)
                }}</template>
                <i
                  v-if="loadingSection === 'next'"
                  class="fal fa-spinner fa-spin"
                ></i>
              </button>
            </div>
          </div>
        </template>
      </div>
      <div class="spacer" :class="sectionBackgroundColor(section)"></div>
    </div>
    <!-- The loader will be rendered absolute, top: 0 to overlap the questions div -->
    <div v-if="loadingSection" class="loading-info">
      <div class="center">
        <i class="fal fa-spinner fa-2x fa-spin"></i>
      </div>
    </div>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import Vue from 'vue';
import question from '@/components/Question.vue';
import languages from '@/languages';
import { questionType } from '@/api';
import { isHeightQuestion, isWeightQuestion } from '@/lib/functions';

export default {
  components: { question },
  props: [
    'closeScreening',
    'isLocal',
    'screening',
    'questions',
    'section',
    'loadingSection',
    'isLanguageRtl',
  ],
  data() {
    return {
      scrollStatus: 'none',
      autoScrollingTimeout: null,
    };
  },
  created() {
    this.emitFinished();
    this.updateScrollStatus();
  },
  mounted() {},
  watch: {
    section() {
      Vue.nextTick(() => {
        document.getElementById('scroller').scrollTop = 0;
        this.updateScrollStatus();
      });
    },
    questions() {
      this.emitFinished();
    },
    isFinished() {
      this.emitFinished();
    },
  },
  computed: {
    visibleQuestions() {
      return this.questions.filter(this.calculateQuestionVisibility);
    },
    invisibleQuestions() {
      return this.questions.filter((q) => !this.calculateQuestionVisibility(q));
    },
    liveQuestions() {
      let futureIsNow = false;

      return this.visibleQuestions.filter((question) => {
        if (futureIsNow) {
          return false;
        }

        if (!this.isQuestionAnswered(question)) {
          futureIsNow = true;
        }

        return true;
      });
    },
    selectedOptions() {
      let selectedOptions = [];
      this.questions.forEach((question) => {
        if (this.isQuestionAnswered(question)) {
          if (
            question.type === questionType.select ||
            question.type === questionType.score
          ) {
            selectedOptions.push(question.answer);
          }
          if (question.type === questionType.multiSelect) {
            question.answer.forEach((a) => selectedOptions.push(a));
          }
        }
      });
      return selectedOptions;
    },
    isFinished() {
      return this.questions.every(
        (q) => !this.isQuestionVisible(q) || this.isQuestionAnswered(q)
      );
    },
    scrollTargetQuestion() {
      if (!this.liveQuestions || this.liveQuestions.length === 0) {
        return null;
      }

      const nextQuestion = this.liveQuestions[this.liveQuestions.length - 1];

      if (this.isQuestionAnswered(nextQuestion)) {
        return nextQuestion;
      }

      // one before.
      const oneBeforeNextQuestion =
        this.liveQuestions.length > 1
          ? this.liveQuestions[this.liveQuestions.length - 2]
          : null;

      if (
        oneBeforeNextQuestion &&
        (oneBeforeNextQuestion.special_type === 'medication' ||
          oneBeforeNextQuestion.type === 'info')
      ) {
        return oneBeforeNextQuestion;
      }

      return nextQuestion;
    },
    isSmall() {
      return this.$mq === 'small';
    },
  },
  methods: {
    translate(item, key, fallback = true) {
      return languages.translate(this.$i18n.locale, item, key, fallback);
    },
    getTargetElm() {
      return this.scrollTargetQuestion
        ? document.getElementById('question-' + this.scrollTargetQuestion.id)
        : null;
    },
    getWrapperElm() {
      return document.getElementById('wrapper');
    },
    getScrollerElm() {
      return document.getElementById('scroller');
    },
    getWrapperHeight() {
      let wrapper = this.getWrapperElm();
      return wrapper ? wrapper.clientHeight : 0;
    },
    getCorrection() {
      return this.$mq === 'large' ? 145 : 50;
    },
    calculateScrollStatus() {
      let target = this.getTargetElm();

      if (target) {
        if (
          target.getBoundingClientRect().bottom <
          this.getWrapperHeight() / 2 + this.getCorrection()
        ) {
          if (this.getScrollerElm().scrollTop > 0) {
            return 'up';
          }
        }
        if (
          target.getBoundingClientRect().top >
          this.getWrapperHeight() / 2 + this.getCorrection()
        ) {
          return 'down';
        }
      }
      return 'none';
    },
    updateScrollStatus() {
      this.scrollStatus = this.calculateScrollStatus();
    },
    onScroll() {
      if (!this.autoScrollingTimeout) {
        this.updateScrollStatus();
      } else {
        this.scrollStatus = 'none';
      }
    },
    scrollIfNeeded() {
      if (this.calculateScrollStatus() !== 'none') {
        //ignore onScroll events for 1 second
        if (this.autoScrollingTimeout) {
          window.clearTimeout(this.autoScrollingTimeout);
        }
        this.autoScrollingTimeout = window.setTimeout(() => {
          this.autoScrollingTimeout = null;
        }, 1000);

        this.scroll();
      }
    },
    scroll() {
      let target = this.getTargetElm();

      if (!target) {
        return;
      }

      let totalMargin = this.getWrapperHeight() - target.offsetHeight;

      if (totalMargin < 0) {
        totalMargin = 0;
      }

      this.getScrollerElm().scrollBy({
        behavior: 'smooth',
        top:
          target.getBoundingClientRect().top -
          totalMargin / 2 -
          this.getCorrection(),
        left: 0,
      });
    },
    sectionButtonPosition(section) {
      return section.button_position;
    },
    sectionButtonText(section) {
      return this.translate(section, 'button_text', true);
    },
    sectionBackgroundColor(section) {
      return section.background_color;
    },
    emitFinished() {
      this.$emit('setFinished', this.isFinished);
    },
    toggleModal(data) {
      this.$emit('toggleModal', data);
    },
    setAnswer(data) {
      if (data.question.special_type === questionType.anesthesiaChoice) {
        // eslint-disable-next-line vue/no-mutating-props
        this.screening.chosen_anesthesia = data.answer;
      }

      data.question.answer = data.answer;
      this.invisibleQuestions.forEach(
        (q) => (q.answer = q.type === 'multiselect' ? [] : null)
      );

      this.$emit('storeAnswer', data);

      if (
        data.question.type === questionType.select ||
        data.question.type === questionType.score
      ) {
        Vue.nextTick(() => {
          this.scrollIfNeeded();
        });
      } else {
        Vue.nextTick(() => {
          this.updateScrollStatus();
        });
      }
    },
    next() {
      this.$emit('next');
    },
    calculateQuestionVisibility: function (question) {
      const VOID = 'void'; //there is no option
      const PENDING = 'pending'; //the question is not answered (yet)
      const ANSWERED = 'answered'; //the question is answered, but the option is not selected
      const MATCH = 'match'; //the option is selected

      const calculateOptionStatus = (optionId) => {
        const optionQuestion = this.findQuestionByOptionId(optionId);

        if (!optionQuestion) {
          return VOID;
        }

        if (this.calculateQuestionVisibility(optionQuestion)) {
          if (this.selectedOptions.indexOf(optionId) !== -1) {
            return MATCH;
          }

          if (this.isQuestionAnswered(optionQuestion)) {
            return ANSWERED;
          }
        }

        return PENDING;
      };

      const operator = {
        or: (a, b) => !!(a || b),
        and: (a, b) => !!(a && b),
      }[question.condition_operator || 'or'];

      const cond1 = question.condition_option_id;
      const cond2 = question.condition_option_2_id;

      const match1 = calculateOptionStatus(cond1);
      const match2 = calculateOptionStatus(cond2);

      const a = match1 === MATCH && match2 !== PENDING;
      const b = match2 === MATCH && match1 !== PENDING;

      const isVoid = match1 === VOID && match2 === VOID;

      // ignore b if cond2 is not given, just look at 'a'.
      if (!cond2) {
        return isVoid || !!a;
      }

      return isVoid || operator(a, b);
    },
    findQuestionByOptionId(optionId) {
      return optionId
        ? this.questions.find((q) => !!q.options.find((o) => o.id === optionId))
        : null;
    },
    isQuestionAnswered(question) {
      if (question.special_type === questionType.medication) {
        return !(question.answer || []).some(
          (row) =>
            !(row.name || row.question_option_id) && (row.dose || row.frequency)
        );
      }

      if (question.type === questionType.info) {
        return true;
      }

      if (question.type === questionType.multiSelect) {
        return question.answer.length > 0;
      }

      if (isHeightQuestion(question)) {
        return (
          question.answer && question.answer >= 100 && question.answer <= 250
        );
      }

      if (isWeightQuestion(question)) {
        return (
          question.answer && question.answer >= 20 && question.answer <= 400
        );
      }

      return !!question.answer;
    },
    isQuestionFuture(question) {
      return !this.isQuestionLive(question);
    },
    isQuestionLive(question) {
      return !!this.liveQuestions.find((q) => question.id === q.id);
    },
    isQuestionVisible(question) {
      return !!this.visibleQuestions.find((q) => question.id === q.id);
    },
  },
};
</script>

<style scoped lang="scss">
.loading-info {
  display: block;
  position: absolute;
  top: 45%;
  width: 100%;

  .center {
    text-align: center;
  }
}

.wrapper.large {
  height: calc(100% - 50px);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .scroll-up-button {
    padding-top: 8px;
    margin: 15px 30px;
    align-self: flex-start;
  }

  .scroll-down-button {
    padding-top: 10px;
    margin: 15px 30px;
    align-self: end;
  }

  .scroll-up-button,
  .scroll-down-button {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    font-size: 12px;
  }
}

.wrapper:not(.large) {
  height: 100%;
  width: 100%;
  position: relative;

  .scroll-up-button {
    padding-top: 5px;
    top: 10px;
  }

  .scroll-down-button {
    padding-top: 6px;
    bottom: 10px;
  }

  .scroll-up-button,
  .scroll-down-button {
    right: 10px;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    font-size: 10px;
  }
}

.scroll-up-button,
.scroll-down-button {
  cursor: pointer;
  text-align: center;
  vertical-align: top;

  position: absolute;

  z-index: 3000;

  /*background-color: #e9e9e9;*/
  /*border-color: #e9e9e9;*/
  /*color: #969696;*/

  background-color: $answer-button-bg-color;
  border-width: 0;
  box-shadow: 0 0 2px 0 lightgrey;
  color: white;
}

.scroller.large {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  .questions,
  .spacer {
    background-color: white !important;
  }
}

.scroller:not(.large) {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  height: 100%;
  position: relative;
}

.spacer {
  height: 40%;
}

.questions {
  color: $question-text-color;
  /*font-size: 11pt;*/
  min-height: 100%;
  padding: 15px 4% 60px 4%;
  margin: 0 0;
  .buttoncol {
    font-weight: 600;
    text-align: center;

    .nextbutton-mobile {
      margin: 0 !important;
    }
  }
  button {
    margin-top: 50px;
    margin-left: 30px;
  }
}
.questions.dark,
.spacer.dark {
  padding: 50px;
}
</style>
