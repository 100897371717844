<template>
  <transition-group name="modal">
    <div class="modal-mask" :key="'mask'" @click.once="$emit('closeModal')">
      <div
        class="modal-container"
        :class="$mq"
        :key="'modal-1'"
        @click.stop.prevent=""
      >
        <div class="modal-body flex flex-wrap justify-center">
          <div class="flex justify-center flex-col">
            <slot name="body">
              <h3 class="text-center pb-4">
                {{ ta('exit.prompt') }}
              </h3>
              <button
                class="btn btn-novacair-selected"
                @click="$emit('closeScreening')"
              >
                {{ ta('exit.confirm') }}
              </button>
            </slot>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-novacair-light" @click="$emit('closeModal')">
            {{ ta('exit.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
import { adminI18n } from '@/i18n';

export default {
  name: 'QuestionModal',
  props: [],
  data() {
    return {
      pincode: '',
      targetPincode: process.env.VUE_APP_PINCODE,
    };
  },
  methods: {
    ta(key) {
      return adminI18n.t(key);
    },
  },
};
</script>

<style scoped lang="scss">
.password {
  font-family: $font-family-password;
}

.modal-mask {
  position: fixed;
  display: flex;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease;
  justify-content: center;
  align-items: center;
}

.modal-container.large {
  margin-top: 25px;
}

.modal-container {
  width: 50%;
  min-height: 20rem;
  position: absolute;
  z-index: 9999;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow-y: hidden;
  overflow-x: hidden;
}

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  font-size: 15px;
  color: $primary;
  font-family: $font-family-light;
}

.small.modal-container {
  width: 90%;
  left: 5%;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  min-height: 20rem;
  overflow-y: auto;
}

.modal-default-button {
  float: right;
}

/*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
