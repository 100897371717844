<template>
  <div class="language-choice" :class="'fontsize-' + fontSize">
    <div class="info">
      {{ $t('select_language') }}
    </div>
    <div class="languages" :class="{ 'languages-mobile': isSmall }">
      <div
        class="optionpointer horizontal"
        :class="{ 'horizontal-mobile': isSmall }"
        v-for="language in context.supportedLanguages"
        :key="language"
      >
        <button
          :class="{ 'btn-novacair-selected': language === $i18n.locale }"
          class="btn language-choice-button btn-novacair-light"
          :key="language"
          @click.stop.prevent="clickLocale(language)"
        >
          {{ languages.localeMap[language] }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import languages from '@/languages';

export default {
  props: ['context', 'isSmall', 'fontSize'],

  data() {
    return {
      show: false,
      languages: languages,
    };
  },
  mounted() {
    this.show = true;
  },

  methods: {
    clickLocale(locale) {
      this.context.setLocale(locale);
      this.$emit('toggleModalFn');
    },
  },
};
</script>

<style scoped lang="scss">
$modal-z-index: 9999;

.language-choice {
  display: flex;
  flex-direction: column;
  align-items: center;

  .info {
    color: $primary;
    text-align: center;
  }

  .languages-mobile {
    flex-direction: column !important;
  }

  .languages {
    display: flex;
    flex-direction: row;
    max-width: 50em;
    flex-wrap: wrap;
    justify-content: center;

    .horizontal-mobile {
      padding: 15px 12px 0 13px !important;
    }

    .optionpointer.horizontal {
      display: flex;
      flex-direction: row;
      padding: 30px 12px 0 13px;

      .language-choice-button {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        min-height: 45px;
        padding: 0 30px !important;
        width: 150px;
      }

      .btn-novacair-selected {
        font-weight: bold;
      }
    }
  }
}
</style>
