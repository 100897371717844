import axios from 'axios';
import moment from 'moment';

export function getSections() {
  return axios.get('/sections');
}

export function getScreening() {
  return axios.get('/screening');
}

export function storeAnswer(question, answer, sequenceNr) {
  return axios.put('/question/' + question.id + '/answer', {
    answer: answer,
    sequence_nr: sequenceNr,
  });
}

export function storeAnswers(sectionId, answers, sequenceNr) {
  const answerData = {
    answers: answers,
    sequence_nr: sequenceNr,
  };

  return axios.put('/section/' + sectionId + '/answers', answerData);
}

export function getSection(sectionId) {
  return axios.get('/section/' + sectionId);
}

export function getCurrentUser() {
  return axios.get('/me');
}

export function loginTwoFactor(code, locale = 'nl') {
  return axios.post('/login/twofactor?locale=' + locale, { code });
}

export function loginCredentials(email, password, locale = 'nl') {
  return axios.post('/login?locale=' + locale, { email, password });
}

export function openScreeningByPasscode(passcode) {
  return axios.post('/openscreening/passcode', { passcode });
}

export function smsAuth(screeningUUID, smsCode) {
  return axios.post('/twofactor', { screeningUUID, smsCode });
}
export function resendSms(screeningUUID, locale) {
  const query = locale ? `?locale=${locale}` : '';
  return axios.post('/resendsms' + query, { screeningUUID });
}

export function getScreeningByUUID(uuid, locale) {
  const url = `/open-screening`;
  const query = locale ? `?locale=${locale}` : '';
  return axios.post(url + query, { uuid });
}

const downloadFile = (response, filename) => {
  const contentType = response.headers['content-type'] || 'text/plain';
  const contentDisposition = response.headers['content-disposition'] || '';

  contentDisposition.split(';').forEach((setting) => {
    const entry = setting.split('=').map((s) => s.trim());
    if (entry[0] === 'filename') {
      filename = entry[1];
    }
  });

  const blob = new Blob([response.data], { type: contentType });
  const tag = document.createElement('a');

  if ('download' in tag && window.navigator.userAgent.indexOf('Edge') === -1) {
    tag.download = filename;
    const windowUrl = window.URL || window.webkitURL;
    const url = windowUrl.createObjectURL(blob);
    tag.href = url;
    tag.target = '_blank';
    document.body.appendChild(tag);
    tag.click();
    windowUrl.revokeObjectURL(url);
  } else {
    navigator.msSaveBlob(blob, filename);
  }
};

export function downloadQuestionnaire(user) {
  const filename =
    'E-POS ' + user.patient_name + ' ' + moment().format('DD-MM-YYYY') + '.pdf';
  return axios
    .get('/questionnaire/pdf', { responseType: 'blob' })
    .then((response) => downloadFile(response, filename));
}

export function updatePreferredLanguage(locale) {
  return axios.put('/screening/preferredlanguage', { locale: locale });
}

export function getAnesthesiaTechniques() {
  return axios.get(`/anesthesia-techniques`);
}

export function getAnesthesiaVideos() {
  return axios.get(`/anesthesia-videos`);
}

export function switchTenant(tenantId) {
  return axios.post('/switch-tenant', { tenant_id: tenantId });
}

export function exchangeClinicToken(token) {
  return axios.post('/login/token', { token });
}

// List of API section statuses.
export const sectionStatus = {
  // Section contains unanswered questions
  pending: 'pending',

  // Section contains answerable questions, all of which are answered
  finished: 'finished',

  // Section contains only unanswerable questions
  info: 'info',
};

// Typed list of string question types (incomplete for now,
// I'll add them as I encounter them).
export const questionType = {
  multiSelect: 'multiselect',
  select: 'select',
  score: 'score',
  info: 'info',
  number: 'number',
  text: 'text',
  date: 'date',

  // Special types
  anesthesiaChoice: 'anesthesia-choice',
  languageChoice: 'language-choice',
  medication: 'medication',
};
