<template>
  <div class="info">
    <p class="content" v-html="questionText"></p>
  </div>
</template>

<script>
export default {
  name: 'InfoQuestionType',
  props: ['questionText'],
};
</script>

<style scoped lang="scss">
.info {
  display: flex;
  flex-direction: row;
  text-align: start;
  padding: 10px 5px 30px 5px;

  .content {
    white-space: pre-wrap;
  }
}
</style>
