<template>
  <div>
    <div class="flex">
      <MedicationTable
        :selectedOptions="this.selectedBloodthinnerOptions"
        :question="question"
        @setAnswer="(value) => this.$emit('setAnswer', value)"
      />
    </div>
  </div>
</template>

<script>
import MedicationTable from './Inputs/MedicationTable';

export default {
  props: ['question', 'bloodthinnerQuestion'],
  components: { MedicationTable },
  computed: {
    selectedBloodthinnerOptions() {
      if (!this.bloodthinnerQuestion || !this.bloodthinnerQuestion.answer) {
        return [];
      }

      return this.bloodthinnerQuestion.options.filter(
        (option) =>
          !['unknown', 'other'].includes(option.type) &&
          this.bloodthinnerQuestion.answer.find(
            (answer) => answer === option.id
          )
      );
    },
  },
};
</script>
