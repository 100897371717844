<template>
  <div @click="blur" class="container-fluid" :class="'fontsize-' + fontSize">
    <warningmodal
      v-if="warning"
      :warning="warning"
      @toggleModal="warning = null"
    />

    <modal
      v-if="showModal"
      :modalComponent="modelComponent"
      :modalContext="modalContext"
      :fontSize="fontSize"
      :isSmall="isSmall"
      @toggleModal="showModal = null"
    />

    <!-- Loading state -->
    <div v-if="!initialized" class="flex justify-center min-h-40vh">
      <div class="my-auto">
        <i class="fal fa-spinner fa-2x fa-spin"></i>
      </div>
    </div>

    <!-- Screening display -->
    <div v-else class="row layout" :class="{ large: isLarge }">
      <div class="leftpane col-auto">
        <div class="row">
          <img src="@/assets/Logo_EPOS.png" class="logo" alt="E+POS logo" />
        </div>
        <div
          class="menucontainer"
          :class="isLanguageRtl ? 'rtl' : 'ltr'"
          v-if="isMenuActive || isLarge"
        >
          <sections
            :sections="sections"
            :loadingNextSession="loadingNextSession"
            :showLocaleChanger="showLocaleChanger"
            :showSections="sectionIsMenuVisible(currentSection)"
            :currentSection="currentSection"
            :isSidebar="isLarge"
            :closeScreening="closeScreening"
            @setSection="gotoSection"
            @toggleMenu="toggleMenu"
            :supportedLanguages="screening.supported_languages"
            :inMenu="!isLarge"
            :fontSize="fontSize"
            :isLanguageRtl="isLanguageRtl"
            @setFontSize="setFontSize"
            :toggleCustomModal="toggleCustomModal"
            :setLocale="setLocale"
          />
        </div>
      </div>
      <div class="rightpane col-auto">
        <div class="box">
          <headerbar
            :currentSection="currentSection"
            :showMenuButton="!isLarge"
            @toggleMenu="toggleMenu"
            :screening="screening"
            :fontSize="fontSize"
          />
          <template v-if="modalOpen">
            <infomodal :question="modalQuestion" @toggleModal="toggleModal" />
          </template>
          <questions
            v-if="currentSection"
            :isLocal="isLocal"
            :screening="screening"
            :questions="questions"
            :section="currentSection"
            :loadingSection="loadingSection"
            :isLanguageRtl="isLanguageRtl"
            @setFinished="setSectionFinished"
            @toggleModal="toggleModal"
            @storeAnswer="storeAnswer"
            @next="gotoNextSection"
            :closeScreening="closeScreening"
          />
        </div>
        <footerbar
          v-if="currentSection"
          :section="currentSection"
          :hasPrevSection="hasPrevSection"
          :isSectionFinished="isSectionFinished"
          :loadingSection="loadingSection"
          :isLanguageRtl="isLanguageRtl"
          @prev="gotoPreviousSection"
          @next="gotoNextSection"
        />
      </div>
    </div>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import {
  getScreening,
  getSection,
  questionType,
  sectionStatus,
  storeAnswer,
  storeAnswers,
  updatePreferredLanguage,
} from '@/api';
import { findLast, isNil, keyBy, last } from 'lodash';
import questions from '@/components/Questions.vue';
import sections from '@/components/Sections.vue';
import headerbar from '@/components/Header.vue';
import footerbar from '@/components/Footer.vue';
import infomodal from '@/components/InfoModal.vue';
import warningmodal from '@/components/WarningModal.vue';
import modal from '@/components/Modal.vue';
import languages, { getLocaleToUse } from '@/languages';
import { configureLocale, getFallbackLocale } from '@/i18n';
import { getCurrentUser } from '@/lib/functions';

export default {
  components: {
    questions,
    sections,
    headerbar,
    footerbar,
    infomodal,
    warningmodal,
    modal,
  },
  data() {
    return {
      warning: null,
      showModal: false,

      // Currently active section
      activeSection: null,

      // List of questions in the currently loaded section
      questions: null,

      isMenuActive: false,

      // null, 'loading', 'next' or 'prev' value indicating which
      // section is currently loading.
      loadingSection: null,

      // used when transitioning between sessions.
      loadingNextSession: false,
      modalOpen: false,
      modalQuestion: null,
      modelComponent: null,
      modalContext: null,
      user: null,
      sections: null,
      screening: null,

      // Version ID of the screening questionnaire
      versionId: null,

      // Current screening session ID
      sessionId: null,

      // Number used to sequence requests that save answers
      sequenceNr: null,

      fontSize: sessionStorage.getItem('fontSize') || 'm',
    };
  },
  async created() {
    try {
      // Load the initial screening state
      await this.loadState();
    } catch (e) {
      console.error(e);
      this.closeScreening();
    }

    // Open the section based on the current URL
    this.setSectionFromUrl();

    window.addEventListener('beforeunload', (e) => {
      const notFinal = this.currentSection
        ? languages.translate(
            this.$i18n.locale,
            this.currentSection,
            'button_text',
            false
          )
        : true;

      if (notFinal) {
        e.returnValue = '';
      }
    });
  },
  watch: {
    urlSection() {
      this.setSectionFromUrl();
    },
  },
  computed: {
    headful() {
      return {
        title: this.sectionTitle || 'E+POS - Preoperatieve Screening',
        description: 'E+POS - Preoperatieve Screening',
      };
    },
    hasPrevSection() {
      return (this.currentSection?.index ?? 0) > 0;
    },
    showLocaleChanger() {
      // Dont show if language question is currently visible.
      const sectionHasLanguageQuestion =
        this.questions &&
        this.questions.some(
          (q) => q.special_type === questionType.languageChoice
        );

      if (sectionHasLanguageQuestion) {
        return false;
      }

      // If only one supported_language is available, dont show the selector.
      return (this.screening?.supported_languages?.length ?? 0) > 1;
    },
    isLocal() {
      return !!sessionStorage.getItem('adminToken');
    },
    initialized() {
      return (
        this.sequenceNr &&
        this.sequenceNr > 0 &&
        this.activeSection &&
        this.questions &&
        this.sections &&
        this.screening
      );
    },

    // Returns the section in the menu section list that matches the current section
    currentSection() {
      return this.initialized
        ? this.sections.find((s) => s.id === this.activeSection.id)
        : null;
    },
    isSectionFinished() {
      // If the section is locally marked finished, we can move to the next section.
      return (
        this.currentSection &&
        this.currentSection.localStatus === sectionStatus.finished
      );
    },
    urlSection() {
      return this.$route.params.section;
    },

    isSmall() {
      return this.$mq === 'small';
    },
    isMedium() {
      return this.$mq === 'medium';
    },
    isLarge() {
      return this.$mq === 'large';
    },
    isLanguageRtl() {
      return this.$i18n.locale === 'ar';
    },
    sectionTitle() {
      if (!this.currentSection) {
        return 'E+POS';
      }

      const title =
        this.currentSection.title[this.$i18n.locale] ??
        this.currentSection.title.nl;
      return 'E+POS - ' + title;
    },
  },
  methods: {
    /**
     * Use this method to show a modal with a
     *
     * @param component
     * @param modalContext
     */
    toggleCustomModal(component, modalContext = {}) {
      this.modelComponent = component;
      this.modalContext = modalContext;
      this.showModal = !this.showModal;
    },
    updateLocaleAttributes(locale) {
      this.$root.$i18n.locale = locale;
      configureLocale(locale);
    },
    setLocale(locale) {
      updatePreferredLanguage(locale).then(() => {
        this.updateLocaleAttributes(locale);
        this.screening.preferred_language = locale;
      });
    },
    translate(item, key) {
      return languages.translate(this.$i18n.locale, item, key);
    },
    setFontSize(fontSize) {
      this.fontSize = fontSize;
      sessionStorage.setItem('fontSize', fontSize);
    },
    sectionButtonPosition(section) {
      return section.button_position;
    },
    sectionButtonText(section) {
      return section.button_text;
    },
    sectionBackgroundColor(section) {
      return section.background_color;
    },
    sectionIsMenuVisible(section) {
      return section?.menu_status !== 'hidden';
    },
    getUser() {
      return getCurrentUser();
    },
    getScreeningId() {
      let user = this.getUser();
      return user ? user.screening_id : null;
    },

    // Loads or refreshes the state of the screening session / questionnaire.
    // Does not change any routing location or section positions.
    async loadState() {
      const screeningId = this.getScreeningId();
      if (!screeningId) {
        this.closeScreening();
        return;
      }

      const data = (await getScreening()).data;
      this.sequenceNr = data.sequence_nr;
      this.versionId = data.version_id;
      this.sessionId = data.session_id;
      this.setScreening(data.screening);
      this.setSections(data.sections);
    },

    // Updates the local section list with a section response
    setSections(sections) {
      this.sections = sections.map((section, index) => ({
        ...section,

        // Track the index for convenience
        index: index,

        // Each section in our list has two statuses:
        // - The regular `status`, which is the status returned from the API
        // - A local status, which can change without help of the API when
        //   we answer individual questions in the question list. This starts
        //   out at the same value as the API status but can be changed locally.
        //
        // We track the local status so we don't have to request an up-to-date status
        // from the API on each answer save, while still only allowing progressing from
        // an unfinished section if it has been confirmed by the server by pressing "next".
        // See Sections.vue:menuSections() for more details.
        localStatus: section.status,
      }));
    },

    // Updates the local screening information with a screening response
    setScreening(screening) {
      // Force the currently set locale to something supported by the screening.
      const supported = screening.supported_languages;

      // List of locale preferences in order of relevance
      const preferences = [
        sessionStorage.getItem('locale'),
        screening.preferred_language,
        (navigator.languages && navigator.languages[0]) || navigator.language,
        getFallbackLocale(),
        'nl', // In case that wasn't already the fallback
      ].filter((pref) => !!pref);

      const locale =
        getLocaleToUse(supported, preferences) ?? getFallbackLocale();

      screening.preferred_language = locale;
      this.updateLocaleAttributes(locale);
      this.screening = screening;

      this.assertValidScreening();
    },
    assertValidScreening() {
      if (this.screening.cancelled_at) {
        this.closeScreening();
      }
    },

    // Returns the first `pending` section in the list of sections, or the first
    // `info` section that has no finished sections following it.
    initialSection() {
      const lastFinished =
        findLast(this.sections, (s) => s.status === sectionStatus.finished)
          ?.index ?? -1;

      return (
        this.sections.find(
          (s) =>
            s.status === sectionStatus.pending ||
            (s.status === sectionStatus.info && lastFinished < s.index)
        ) ?? last(this.sections)
      );
    },

    // Loads the section best matching the current URL value. If the
    // URL value is 'continue', this will try to load the next pending
    // section. Any other URL value is matched to the URL translation
    // of a section, with a restriction on which sections we can load
    // to those that aren't after another pending section.
    setSectionFromUrl() {
      this.loadingSection = this.loadingSection || 'loading';

      const section =
        this.urlSection === 'continue'
          ? null
          : this.sections.find(
              (s) => this.translate(s, 'url') === this.urlSection
            );

      if (!section) {
        this.gotoInitialSection();
        return;
      }

      // Make sure we don't inadvertently select a section that shouldn't be reached yet.
      // The section we want to open must come before the first pending section.
      const firstPending =
        this.sections.find((s) => s.status === sectionStatus.pending) ??
        last(this.sections);
      if (section.index > firstPending.index) {
        this.gotoInitialSection();
        return;
      }

      this.loadSection(section);
    },

    // Loads the given section to be displayed
    async loadSection(section) {
      const data = (await getSection(section.id)).data;

      // New up to date section list
      this.setSections(data.sections);

      // Map answers to question IDs
      const answerMap = keyBy(data.answers, (a) => a.question_id);

      // Gets the answer for a given question
      const getAnswer = (q) => {
        const a = answerMap[q.id];
        const answerValue = a?.answer;
        if (
          [questionType.anesthesiaChoice, questionType.languageChoice].includes(
            q.special_type
          )
        ) {
          return answerValue ?? null;
        }

        const type = q.type;
        if (type === questionType.multiSelect) {
          // For multiselect questions, split to individual IDs
          return (
            answerValue
              ?.split(',')
              .filter((v) => !!v)
              .map((v) => parseInt(v)) ?? []
          );
        }

        if (type === questionType.medication) {
          // Initialize to an empty array if no answer is present
          return answerValue ?? [];
        }

        if ([questionType.select, questionType.score].includes(type)) {
          return answerValue ? parseInt(a.answer) : null;
        }

        return answerValue ?? null;
      };

      // Initialize all questions with their known answers
      this.questions = data.questions.map((q) => ({
        ...q,
        answer: getAnswer(q),
      }));

      this.activeSection = data.section;
      this.loadingSection = null;
    },

    storeAnswer(data) {
      this.sequenceNr += 1;
      return storeAnswer(data.question, data.answer, this.sequenceNr);
    },

    // Pushes all answers in the current section to the API.
    storeAnswers() {
      const answers = this.questions
        // Filter by questions that actually have an answer specified to filter out purely
        // informational questions.
        .filter((q) => !isNil(q.answer))
        .map((question) => ({
          id: question.id,
          answer:
            question.type === questionType.multiSelect
              ? question.answer.join(',')
              : question.answer,
        }));

      this.sequenceNr++;
      return storeAnswers(this.activeSection.id, answers, this.sequenceNr);
    },

    // Sets the *local* finished status of the current section. This is used
    // to mark sections (in)complete as answers are updated, to determine whether
    // we can press the "next" button, and whether we can show a checkmark.
    setSectionFinished(isFinished) {
      this.currentSection.localStatus = isFinished
        ? sectionStatus.finished
        : sectionStatus.pending;
    },

    // Opens the most appropriate initially accessible question in the questionnaire.
    // This is the first pending section, or the info section before that.
    gotoInitialSection() {
      this.gotoSection(this.initialSection());
    },
    gotoSection(section) {
      this.isMenuActive = false;
      this.$router.push({
        name: 'screening',
        params: {
          section: this.translate(section, 'url'),
        },
      });
    },
    gotoPreviousSection() {
      this.loadingSection = 'prev';
      this.gotoSection(this.sections[this.currentSection.index - 1]);
    },
    closeScreening() {
      sessionStorage.removeItem('token');

      if (sessionStorage.getItem('adminToken')) {
        this.$router.push('/clinic');
      } else {
        this.$router.push('/login');
      }
    },
    showWarning(warning = null) {
      this.warning = warning || this.$i18n.t('section_save_error');
      this.loadingSection = null;
    },
    async gotoNextSection() {
      this.loadingSection = 'next';

      try {
        const data = (await this.storeAnswers()).data;

        // Update the local screening / section data with what the API gave us
        this.setScreening(data.screening);
        this.setSections(data.sections);

        // If saving the answers didn't lead to our screening being finished,
        // show a warning.
        if (
          ![sectionStatus.finished, sectionStatus.info].includes(data.status)
        ) {
          this.showWarning();
          return;
        }

        // If our section was finished and no new screening session was started,
        // simply move to the next section.
        if (this.versionId === data.version_id) {
          const nextSection = this.sections[this.currentSection.index + 1];
          this.gotoSection(nextSection);
          return;
        }

        this.versionId = data.version_id;
        this.sessionId = data.session_id;

        // Load the first pending section in the list (almost certainly
        // the first section there is). Note that gotoSection will eventually
        // call loadSection, which in turn will set this.loadingSection = null.
        this.gotoSection(this.initialSection());
      } catch (e) {
        // This does this.loadingSection = null.
        this.showWarning();
      }
    },
    blur() {
      this.isMenuActive = false;
      document.activeElement.blur();
    },
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    toggleModal(data) {
      this.modalOpen = !this.modalOpen;
      this.modalQuestion = data;
    },
  },
};
</script>

<style lang="scss">
.btn.answer {
  /*font-size: 15px;*/
}

.container-fluid {
  &.fontsize-xs {
    pre,
    .btn.answer {
      font-size: 13px;
    }
    font-size: 13px;
  }

  &.fontsize-s {
    pre,
    .btn.answer {
      font-size: 14px;
    }
    font-size: 14px;
  }

  &.fontsize-m {
    pre,
    .btn.answer {
      font-size: 15px;
    }
    font-size: 15px;
  }

  &.fontsize-l {
    pre,
    .btn.answer {
      font-size: 18px;
    }
    font-size: 18px;
  }

  &.fontsize-xl {
    pre,
    .btn.answer {
      font-size: 20px;
    }
    font-size: 20px;
  }
}
</style>

<style scoped lang="scss">
.layout.large {
  margin: 0 -15px;
  background-color: $neutral;

  .leftpane {
    background-color: $color-cloud;
    border-right: solid 2px white;
    border-left: solid 2px white;
    height: 100vh;
    width: 310px;
    padding: 0 30px;
    .logo {
      height: 30px;
      margin: 25px 20px 40px 20px;
    }
    .sections {
      font-family: $font-family-regular;
      font-size: 14px;
    }
  }

  .rightpane {
    background-color: $neutral;
    width: 70%;
    padding: 95px 45px 0 45px;
    height: 100vh;

    .box {
      position: relative;
      height: calc(100% - 110px);
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 6px;

      .header {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
    }

    .footer {
      padding: 8px 10px;
      margin-top: 15px;
    }
  }
}

.layout:not(.large) {
  background-color: $neutral-content;

  margin: 0 -15px;

  .leftpane {
    .logo {
      display: none;
    }
    .menucontainer.rtl {
      display: block;
      width: 260px;
      right: -20px;
      position: absolute;
      text-align: left;
      margin-right: 20px;
      top: 0;
      left: auto;
      z-index: 1040;
    }
    .menucontainer.ltr {
      display: block;
      width: 260px;
      right: auto;
      //left: auto;
      position: absolute;

      //inset: logical 10px 20px 30px 40px;
      text-align: left;
      margin-right: 20px;
      top: 0;
      left: 0;
      z-index: 1040;
    }
  }
  .rightpane {
    background-color: $neutral-content;

    width: 100%;
    height: 100%;
    margin: 0 0;
    padding: 50px 0;

    .box {
      position: relative;
      margin: 0;
      padding: 0;
      height: 100%;
      overflow-y: hidden;
      overflow-x: hidden;
    }

    .header {
      position: fixed;
      right: 0;
      top: 0;
      left: 0;
      z-index: 1030;
    }

    .footer {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1030;

      padding: 8px 0 7px 0;
    }
  }
}
</style>
