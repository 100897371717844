<template>
  <div class="loader-container">
    <i class="fal fa-spinner fa-3x fa-spin"></i>
  </div>
</template>
<script setup>
// Initiates and processes a clinic portal login through the login flow of
// the admin client. The procedure is explained in admin-client ClinicGateway.vue.
import { onMounted } from 'vue';
import { exchangeClinicToken } from '@/api';
import { useRouter } from 'vue-router/composables';

const stateKey = 'clinic-gateway-state';

// Resolves the admin client URL from the current hostname
function resolveAdminClientUrl() {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:8080';
  }

  if (window.location.hostname.startsWith('epos.')) {
    return 'https://admin.' + window.location.hostname.substring(5);
  }

  throw new Error(
    'Unable to resolve admin client hostname for host: ' +
      window.location.hostname
  );
}

const router = useRouter();

function initiateLogin() {
  // Generate a random state string and store it in the session
  const state = Math.random().toString(36).substring(2);
  sessionStorage.setItem(stateKey, state);

  // Redirect to the clinic-gateway of the admin client
  const adminClientUrl = resolveAdminClientUrl();
  window.location.replace(`${adminClientUrl}/clinic#state=${state}`);
}

async function processLogin(sessionState, state, token) {
  if (!state || !token) {
    sessionStorage.removeItem(stateKey);
    throw new Error(
      'Missing URL state or token required to process clinic login.'
    );
  }

  if (state !== sessionState) {
    throw new Error('State must match session state to process clinic login.');
  }

  const adminToken = (await exchangeClinicToken(token)).data;
  sessionStorage.removeItem(stateKey);
  sessionStorage.setItem('adminToken', adminToken);
  await router.replace({ name: 'loginLocal' });
}

onMounted(() => {
  // If a login was previously initiated, state will be present in
  // session storage.
  const sessionState = sessionStorage.getItem(stateKey);

  // If this is a login redirect, state and token will be present in the hash.
  const hash = (window.location.hash || '#').substring(1);
  const params = new URLSearchParams(hash);
  const state = params.get('state');
  const token = params.get('token');

  if (state || token) {
    processLogin(sessionState, state, token);
    return;
  }

  // If state / token both aren't set, assume this to be a new login
  // initialization.
  initiateLogin();
});
</script>

<style scoped lang="scss">
.loader-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
}
</style>
