<template>
  <div class="landing-page">
    <div class="login ml-auto mr-auto">
      <div class="card">
        <div class="card-header">
          <div class="flex flex-between align-center">
            <img src="@/assets/Logo_EPOS.png" class="logo" alt="E+POS Logo" />
          </div>
        </div>
        <div class="card-body">
          <div class="alert alert-info">{{ ta('select_tenant') }}</div>
          <ul>
            <li
              v-for="tenant in tenants"
              :key="tenant.id"
              class="tenant"
              @click="loginAsTenant(tenant.id)"
            >
              {{ tenant.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { getCurrentAdmin } from '@/lib/functions';
import router from '@/router';
import { adminI18n } from '@/i18n';
import { switchTenant } from '@/api';

const user = ref(null);
const tenants = ref([]);

function ta(key) {
  return adminI18n.t(key);
}

function toClinic() {
  router.replace({ name: 'loginLocal' });
}

async function loginAsTenant(tenantId) {
  if (user.value.tenant_id === tenantId) {
    // No need to switch
    toClinic();
    return;
  }

  const response = await switchTenant(tenantId);
  sessionStorage.setItem('adminToken', response.data);
  toClinic();
}

onMounted(() => {
  user.value = getCurrentAdmin();
  const userTenants = user.value?.all_tenants ?? [];

  if (userTenants.length < 2) {
    toClinic();
    return;
  }

  tenants.value = userTenants;
});
</script>

<style scoped lang="scss">
.login {
  max-width: 600px;
  padding-top: 60px;
}

.logo {
  height: 30px;
  margin: 25px 0 20px 20px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tenant {
  display: block;
  padding: 0.5rem;
  font-size: 1.2rem;
  cursor: pointer;

  &:hover {
    background-color: $color-snow;
  }
}

.tenant + .tenant {
  border-top: 1px solid $color-cloud;
}
</style>
