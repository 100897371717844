<template>
  <div class="wrapper">
    <div
      class="sections"
      :class="[
        (isSidebar ? 'sidebar ' : '') + $mq,
        isLanguageRtl ? 'rtl' : 'ltr',
      ]"
    >
      <close-screening-modal
        v-if="modal === true"
        @closeModal="modal = false"
        @closeScreening="closeScreening"
      />

      <div v-if="!isSidebar" class="header-close">
        <i class="fal fa-times fa-2x togglebutton" @click.stop="toggleMenu"></i>
      </div>

      <template v-if="showSections">
        <div
          class="section"
          v-for="section in menuSections"
          :key="section.id"
          :class="{
            disabled: isMenuDisabled,
            accessible: section.accessible,
            active: section.active,
            first: section.first,
            middle: section.middle,
            last: section.last,
          }"
        >
          <a
            @click.stop.prevent="onSectionClick(section)"
            :class="{
              disabled: isMenuDisabled,
              accessible: section.accessible,
              active: section.active,
              first: section.first,
              middle: section.middle,
              last: section.last,
            }"
            href="#"
            class="section-link"
          >
            <span>{{ sectionTitle(section) }}</span
            ><i v-if="section.checkmark" class="fal fa-check float-right"></i>
          </a>
        </div>
      </template>
      <template v-if="inMenu">
        <div class="settings flex flex-wrap align-center flex-between">
          <div
            class="w-100 section settingsitem topborder"
            v-if="showLocaleChanger"
          >
            <locale-changer
              :setLocale="setLocale"
              :supportedLanguages="supportedLanguages"
              :toggleCustomModal="toggleCustomModal"
            />
          </div>
          <div class="w-100 section settingsitem">
            <font-resizer
              :dark="true"
              :fontSize="fontSize"
              @setFontSize="(value) => $emit('setFontSize', value)"
            />
          </div>
          <div class="w-100 section settingsitem">
            <div
              class="flex flex-between"
              @click.stop.prevent="modal = true"
              v-if="isLocal"
            >
              <span class="explanation">{{ ta('exit.label') }}</span>
              <i class="fas fa-sign-out white"></i>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="settings" v-if="!inMenu">
      <div class="settingsitem" v-if="showLocaleChanger">
        <locale-changer
          :setLocale="setLocale"
          :supportedLanguages="supportedLanguages"
          :toggleCustomModal="toggleCustomModal"
        />
      </div>
      <div class="settingsitem">
        <font-resizer
          :dark="true"
          :fontSize="fontSize"
          @setFontSize="(value) => $emit('setFontSize', value)"
        />
      </div>
      <div class="settingsitem">
        <div
          class="flex flex-between"
          @click.stop.prevent="modal = true"
          v-if="isLocal"
        >
          <span class="explanation">{{ ta('exit.label') }}</span>
          <i class="fas fa-sign-out white"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import CloseScreeningModal from '@/components/CloseScreeningModal.vue';
import FontResizer from '@/components/FontResizer.vue';
import LocaleChanger from '@/components/LocaleChanger.vue';
import languages from '@/languages';
import { sectionStatus } from '@/api';
import { adminI18n } from '@/i18n';

export default {
  props: [
    'setLocale',
    'closeScreening',
    'sections',
    'currentSection',
    'isSidebar',
    'inMenu',
    'fontSize',
    'showLocaleChanger',
    'supportedLanguages',
    'toggleCustomModal',
    'isLanguageRtl',
    'showSections',
    'loadingNextSession',
  ],
  components: { LocaleChanger, CloseScreeningModal, FontResizer },
  data: function () {
    return {
      modal: false,
    };
  },
  computed: {
    // Lists all sections that should be displayed in the menu, with some
    // additional properties for display.
    // The behavior of the menu is as follows:
    // - If a section has a local finished status, it will get a checkmark.
    // - A section is accessible as long as there are no sections before it with a remote
    //   pending status.
    menuSections() {
      const result = [];
      const current = this.currentSection;
      const menuDisabled = this.isMenuDisabled;

      // Get the index of the last finished section and the current section
      // so we can better decide what to do with info sections and the
      // sections behind them.
      const lastFinishedIndex =
        this.sections.findLast((s) => s.status === sectionStatus.finished)
          ?.index ?? -1;
      const currentIndex = current?.index ?? -1;

      // Tracks if the next section is still accessible. The first section
      // we encounter that isn't finished will set this to false.
      let nextAccessible = true;

      for (const section of this.sections) {
        // If a section has an 'info' status, we don't have information on whether it has been
        // completed or not. The API considers every section without unanswered questions
        // before it accessible, so we need some kind of heuristic to not magically open up
        // sections after info sections that haven't been clicked through yet. To do
        // this, we consider `info` sections that have no `finished` sections following
        // them to be `pending`, unless we currently have a section after them opened,
        // in which case they're marked finished.
        const isInfo = section.status === sectionStatus.info;
        const remoteStatus = isInfo
          ? lastFinishedIndex > section.index || currentIndex > section.index
            ? sectionStatus.finished
            : sectionStatus.pending
          : section.status;

        const remoteFinished = remoteStatus === sectionStatus.finished;
        const localFinished =
          section.localStatus === sectionStatus.finished ||
          section.localStatus === sectionStatus.info;

        const accessible = nextAccessible;

        // For the next section to be accessible, the current section needs to
        // be confirmed finished by the API, and can't have any local changes
        // (i.e. newly appearing questions) that make it pending.
        nextAccessible = nextAccessible && localFinished && remoteFinished;

        // Don't add sections that are hidden from the menu (but include
        // them in the accessibility calculation)
        if (!section.in_menu) {
          continue;
        }

        const checkmark =
          // Don't show any checkmarks if the menu is disabled
          !menuDisabled &&
          // Never show checkmarks for the final section
          !section.is_final &&
          // The section needs to have a local finished status,
          // which will match the remote status if it isn't open,
          // and be computed from its contents if it is.
          localFinished &&
          // We can't just give future info statuses a checkmark (which
          // would happen based on localFinished alone), but we still want
          // the locally finished status to update the checkmark.
          // For info items, we check remoteFinished to deal with any
          // info items further up in the menu, and also add a checkmark
          // to the item if it is accessible, which basically means it
          // is open or has been opened.
          (!isInfo || remoteFinished || accessible);

        result.push({
          ...section,
          accessible,
          checkmark,
          active: section.id === current?.id,
          first: result.length === 0,

          // This will be true for everything except the last section,
          // which we'll update at the end.
          middle: result.length > 0,
          last: false,
        });
      }

      if (result.length) {
        const last = result[result.length - 1];
        last.middle = false;
        last.last = true;
      }

      return result;
    },
    isMenuDisabled() {
      return (
        this.loadingNextSession || this.currentSection?.menu_status !== 'active'
      );
    },
    isLocal() {
      return !!sessionStorage.getItem('adminToken');
    },
  },
  methods: {
    // Admin translation
    ta(key, values) {
      return adminI18n.t(key, values);
    },
    sectionTitle(section) {
      return languages.translate(this.$i18n.locale, section, 'title');
    },
    toggleMenu() {
      this.$emit('toggleMenu');
    },
    onSectionClick: function (section) {
      if (this.isMenuDisabled) {
        return;
      }

      if (!section.accessible) {
        return;
      }

      // Find the matching section in the original list and emit it
      const backingSection = this.sections.find((s) => s.id === section.id);
      this.$emit('setSection', backingSection);
    },
  },
};
</script>

<style scoped lang="scss">
.sections.sidebar {
  border-top-left-radius: 6px;
  a.first {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}

.sections.small {
  a {
    padding: 10px 12px 10px 20px;
  }
  .header-close {
    padding: 8px 20px 8px 20px;
  }
}

.sections.medium {
  a {
    padding: 10px 12px 10px 20px;
  }
  .header-close {
    padding: 5px 20px 5px 20px;
  }
}

.sections.medium,
.sections.large {
  .section.last {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    a {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.settings {
  padding: 10px;
  font-size: 15px;
  margin-top: 1rem;

  background-color: white;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 6px;

  cursor: pointer;
  color: $primary;
  font-weight: bold;

  .settingsitem {
    margin-top: 1em;
    padding: 0 0.5em 0 0.5em;
  }
}

.sections.ltr {
  border-top-right-radius: 6px;
}

.sections.rtl {
  border-top-left-radius: 6px;
}

.sections {
  font-size: 15px !important;
  float: left;
  display: block;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: gray;
  width: 100%;
  padding: 0;

  .section.first,
  .section.middle {
    border-bottom: solid 1px $color-cloud;
  }

  .section.exit {
    a {
      border: 1px solid $primary;
      font-weight: bold;
    }
    a:hover {
      color: $primary;
      cursor: pointer;
    }
  }

  .section.settings-item {
    padding: 2px 10px 5px 10px;
    border-top: 1px solid $primary;

    &.topborder {
      border-top: 1px solid $primary;
    }
  }

  a.section-link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    //display: block;
    padding: 15px 12px 15px 12px;
    text-decoration: none;
    color: $primary;
    line-height: 1;

    span.sectionicon {
      float: right;
    }
  }

  a:not(.accessible),
  a.disabled {
    opacity: 0.3;
    cursor: default;
  }

  a.active {
    background-color: $primary;
    color: $primary-content;
    font-weight: 600;
  }

  .header-close {
    display: block;
    padding: 10px 20px 10px 30px;
    text-decoration: none;
    color: $primary;
    line-height: 1;

    .togglebutton:hover {
      cursor: pointer;
    }
  }
}
</style>
