<template>
  <div class="anesthesia-description">
    <div class="field">
      <div class="value techniquename">
        {{ translate(singleTechnique, 'title') }}
      </div>
    </div>
    <div class="field">
      <div class="value techniquedescription">
        {{ translate(singleTechnique, 'description') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnesthesiaDescriptionQuestionType',
  props: ['applicableTechniques'],
  methods: {
    translate(option, type) {
      return this.$parent.translate(option, type);
    },
  },
  computed: {
    singleTechnique() {
      return this.applicableTechniques && this.applicableTechniques.length > 0
        ? this.applicableTechniques[0]
        : null;
    },
  },
};
</script>

<style scoped lang="scss">
.anesthesia-description {
  padding-left: 40px;

  .techniquename {
    padding-top: 25px;
    font-weight: bold;
  }
  .techniquedescription {
    padding-top: 10px;
    padding-bottom: 25px;
  }
}
</style>
