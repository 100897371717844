<template>
  <div class="scorerow">
    <div class="scorequestion">
      <span class="pre-wrap" v-html="questionText"></span>
    </div>
    <div
      class="answerswrapper"
      :class="{ 'many-options': question.options.length > 10 }"
    >
      <div class="answers score">
        <div
          class="optionpointer"
          v-for="option in question.options"
          :key="option.id"
        >
          <div class="option-holder">
            <div
              @click="onClick(option.id)"
              class="option"
              :class="
                answer == option.id
                  ? 'score-novacair-selected'
                  : 'score-novacair-light'
              "
              :disabled="!enabled"
            >
              {{ optionText(option) }}
            </div>
            <div class="optionsubtext">
              <div>{{ optionSubtext(option) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScoreQuestionType',
  props: ['questionText', 'question', 'answer', 'enabled'],
  methods: {
    optionSubtext(option) {
      const text = this.$parent.translate(option, 'text');
      return text.split(/\s(.+)/)[1];
    },
    optionText(option) {
      const text = this.$parent.translate(option, 'text');
      return text.split(/\s(.+)/)[0];
    },
    onClick(option) {
      this.$emit('onClick', option);
    },
  },
};
</script>

<style scoped lang="scss">
.option-holder {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.question {
  .scorerow {
    .scorequestion {
      display: flex;
      flex-direction: row;
      text-align: start;
      margin-top: 0;
    }

    .answerswrapper {
      margin: 20px 15px 20px 15px;
      padding-bottom: 20px;
      text-align: center;
      display: flex;
      justify-content: center;

      .answers.score {
        display: flex;
        justify-content: center;

        .optionpointer {
          display: flex;

          .option {
            cursor: pointer;
            display: flex;
            padding: 5px;
            border-radius: 30px;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            line-height: 50px;
            color: white;
            text-align: center;
            margin: 2px;
          }

          .score-novacair-selected {
            border: 1px solid $primary;
            background-color: $primary;
            color: white !important;
          }

          .score-novacair-light {
            border: 1px solid $primary;
            background-color: white;
            color: black;
          }
        }

        .optionsubtext {
          font-style: italic;
          display: flex;
          justify-content: center;
          height: 1px;
          width: 5em;

          > * {
            display: flex;
            text-align: center;
          }
        }
      }
    }
  }

  &.small .scorerow {
    width: 100%;
  }

  &.small .answerswrapper {
    .answers.score {
      .optionpointer {
        display: flex;

        .option {
          font-size: 13px !important;
          border-radius: 15px;
          width: 30px;
          height: 30px;
          padding: 5px !important;
        }

        .optionsubtext {
          font-size: 13px !important;
          width: 4.5em;
        }
      }
    }
  }

  // By default we set the width a bit smaller for .optionsubtext that have more than 10 options.
  .many-options .answers.score .optionpointer .optionsubtext {
    width: 4em;
  }

  &.small .answerswrapper,
  &.medium .answerswrapper {
    &.many-options {
      margin: 20px 15px 35px 15px;
    }

    $screenSizes: 0px, 340px, 400px, 500px, 600px;
    $optionSizes: 25px, 25px, 30px, 40px, 45px;

    @each $screenSize in $screenSizes {
      $i: index($screenSizes, $screenSize);
      $size: nth($optionSizes, $i);

      @media only screen and (min-width: $screenSize) {
        &.many-options .answers.score .optionpointer .option {
          border-radius: calc($size / 2);
          height: $size;
          line-height: $size;
          width: $size;
          margin: 2px;
        }

        &.many-options .answers.score .optionpointer .optionsubtext {
          margin: 2px;
          width: $size;
        }
      }
    }
  }
}

.pre-wrap {
  white-space: pre-wrap;
}
</style>
