var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"sections",class:[
      (_vm.isSidebar ? 'sidebar ' : '') + _vm.$mq,
      _vm.isLanguageRtl ? 'rtl' : 'ltr',
    ]},[(_vm.modal === true)?_c('close-screening-modal',{on:{"closeModal":function($event){_vm.modal = false},"closeScreening":_vm.closeScreening}}):_vm._e(),(!_vm.isSidebar)?_c('div',{staticClass:"header-close"},[_c('i',{staticClass:"fal fa-times fa-2x togglebutton",on:{"click":function($event){$event.stopPropagation();return _vm.toggleMenu.apply(null, arguments)}}})]):_vm._e(),(_vm.showSections)?_vm._l((_vm.menuSections),function(section){return _c('div',{key:section.id,staticClass:"section",class:{
          disabled: _vm.isMenuDisabled,
          accessible: section.accessible,
          active: section.active,
          first: section.first,
          middle: section.middle,
          last: section.last,
        }},[_c('a',{staticClass:"section-link",class:{
            disabled: _vm.isMenuDisabled,
            accessible: section.accessible,
            active: section.active,
            first: section.first,
            middle: section.middle,
            last: section.last,
          },attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSectionClick(section)}}},[_c('span',[_vm._v(_vm._s(_vm.sectionTitle(section)))]),(section.checkmark)?_c('i',{staticClass:"fal fa-check float-right"}):_vm._e()])])}):_vm._e(),(_vm.inMenu)?[_c('div',{staticClass:"settings flex flex-wrap align-center flex-between"},[(_vm.showLocaleChanger)?_c('div',{staticClass:"w-100 section settingsitem topborder"},[_c('locale-changer',{attrs:{"setLocale":_vm.setLocale,"supportedLanguages":_vm.supportedLanguages,"toggleCustomModal":_vm.toggleCustomModal}})],1):_vm._e(),_c('div',{staticClass:"w-100 section settingsitem"},[_c('font-resizer',{attrs:{"dark":true,"fontSize":_vm.fontSize},on:{"setFontSize":(value) => _vm.$emit('setFontSize', value)}})],1),_c('div',{staticClass:"w-100 section settingsitem"},[(_vm.isLocal)?_c('div',{staticClass:"flex flex-between",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.modal = true}}},[_c('span',{staticClass:"explanation"},[_vm._v(_vm._s(_vm.ta('exit.label')))]),_c('i',{staticClass:"fas fa-sign-out white"})]):_vm._e()])])]:_vm._e()],2),(!_vm.inMenu)?_c('div',{staticClass:"settings"},[(_vm.showLocaleChanger)?_c('div',{staticClass:"settingsitem"},[_c('locale-changer',{attrs:{"setLocale":_vm.setLocale,"supportedLanguages":_vm.supportedLanguages,"toggleCustomModal":_vm.toggleCustomModal}})],1):_vm._e(),_c('div',{staticClass:"settingsitem"},[_c('font-resizer',{attrs:{"dark":true,"fontSize":_vm.fontSize},on:{"setFontSize":(value) => _vm.$emit('setFontSize', value)}})],1),_c('div',{staticClass:"settingsitem"},[(_vm.isLocal)?_c('div',{staticClass:"flex flex-between",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.modal = true}}},[_c('span',{staticClass:"explanation"},[_vm._v(_vm._s(_vm.ta('exit.label')))]),_c('i',{staticClass:"fas fa-sign-out white"})]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }