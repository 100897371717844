<template>
  <div class="personal">
    <div class="field">
      <div class="label">{{ $t('label-name') }}</div>
      <div class="value">{{ screening.patient.fullname }}</div>
    </div>
    <div class="field">
      <div class="label">{{ $t('label-date-of-birth') }}</div>
      <div class="value">
        {{
          moment(screening.patient.date_of_birth, 'DD-MM-YYYY').format(
            'DD-MM-YYYY'
          )
        }}
      </div>
    </div>
    <div class="field" v-if="!screening.tenant.hide_gender">
      <div class="label">{{ $t('label-gender') }}</div>
      <div class="value">
        {{ $t('gender-' + (screening.patient.gender || 'male')) }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'PersonalQuestionType',
  props: ['screening'],
  data() {
    return {
      moment,
    };
  },
};
</script>

<style scoped lang="scss">
.personal {
  display: flex;
  flex-direction: column;
  padding: 0 20px 50px 20px;
  .field {
    display: flex;
    flex-direction: row;
    text-align: start;
    .label {
      width: 40%;
      min-width: 130px;
      max-width: 200px;
      font-weight: 600;
    }

    .value {
      font-weight: 400;
    }
  }
}
</style>
