var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition-group',{attrs:{"name":"modal"}},[_c('div',{key:'mask',staticClass:"modal-mask",on:{"~click":function($event){return _vm.$emit('toggleModal')}}}),_c('div',{key:'modal-1',staticClass:"modal-container",class:_vm.$mq},[_c('div',{staticClass:"modal-body"},[_vm._t("body",function(){return [_c('pre',[_vm._v(_vm._s(_vm.questionExplanation))]),(_vm.question.id === 102)?_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require("@/assets/three-fingers.png")}})]):_vm._e(),(_vm.question.id === 172)?_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require("@/assets/surgery-risks.png")}})]):_vm._e(),(
            _vm.question.id === 1852 && _vm.$mq === 'small' && _vm.$i18n.locale === 'nl'
          )?_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require("@/assets/medication-small-nl.png")}})]):_vm._e(),(
            _vm.question.id === 1852 && _vm.$mq !== 'small' && _vm.$i18n.locale === 'nl'
          )?_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require("@/assets/medication-large-nl.png")}})]):_vm._e(),(
            _vm.question.id === 1852 && _vm.$mq === 'small' && _vm.$i18n.locale !== 'nl'
          )?_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require("@/assets/medication-small-en.png")}})]):_vm._e(),(
            _vm.question.id === 1852 && _vm.$mq !== 'small' && _vm.$i18n.locale !== 'nl'
          )?_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require("@/assets/medication-large-en.png")}})]):_vm._e()]})],2),_c('div',{staticClass:"modal-footer"},[_vm._t("footer",function(){return [_c('button',{staticClass:"btn btn-novacair-light",on:{"click":function($event){return _vm.$emit('toggleModal')}}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }