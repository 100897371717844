<template>
  <transition name="slide-fade">
    <div key="modal" class="modal-wrapper" v-if="show">
      <div class="modal-mask" :key="'mask'" @click.stop="toggleModalFn"></div>
      <div class="modal-container" key="global-modal">
        <div class="modal-body">
          <component
            :is="modalComponent"
            :context="modalContext"
            :isSmall="isSmall"
            :fontSize="fontSize"
            @toggleModalFn="toggleModalFn"
          />
        </div>

        <div class="modal-footer" :class="{ 'mobile-footer': isSmall }">
          <slot name="footer">
            <button class="btn btn-novacair-light" @click.stop="toggleModalFn">
              {{ $t('close') }}
            </button>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
export default {
  props: ['modalComponent', 'modalContext', 'isSmall', 'fontSize'],
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.show = true;
  },
  methods: {
    toggleModalFn() {
      // Delay the toggle to make sure the locale has been changed
      this.$nextTick(() => {
        this.$emit('toggleModal');
      });
    },
  },
};
</script>

<style scoped lang="scss">
$modal-z-index: 9999;

.modal-wrapper {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $modal-z-index;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-mask {
  position: fixed;
  z-index: $modal-z-index;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-container.large {
  margin-top: 25px;
}

.modal-container {
  position: fixed;
  z-index: $modal-z-index + 1;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: hidden;
  overflow-x: hidden;
}

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  font-size: 15px;
  color: $primary;
  font-family: $font-family-light;
}

.small.modal-container {
  width: 90%;
  left: 5%;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  height: calc(100% - 100px);
  overflow-y: auto;
}

.modal-footer.mobile-footer {
  justify-content: center;
}

.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
</style>
