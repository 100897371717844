<template>
  <div class="header row" @click.stop="toggleMenu">
    <div class="button" :class="isSmall ? 'col-2' : 'col-4'">
      <button v-if="showMenuButton" class="btn btn-novacair">
        <i class="fal fa-bars fa-2x white"></i>
      </button>
    </div>
    <div class="title" :class="isSmall ? 'col-8' : 'col-4'">
      {{ currentSectionTitle }}
    </div>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import languages from '@/languages';

export default {
  props: ['showMenuButton', 'currentSection', 'screening', 'fontSize'],
  methods: {
    toggleMenu() {
      this.$emit('toggleMenu');
    },
  },
  computed: {
    isSmall() {
      return this.$mq !== 'large';
    },
    currentSectionTitle() {
      return this.currentSection
        ? languages.translate(this.$i18n.locale, this.currentSection, 'title')
        : '';
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  margin: 0 0;
  padding: 0 0;
  background-color: $primary !important;
  min-height: 50px;

  .button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 -10px;
  }

  .fonts {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    color: white;
    padding-right: 20px;
  }

  .logoutbutton {
    background-color: $primary;
    border-color: white;
    color: white;
    margin: 8px -20px 8px 10px;
    vertical-align: middle;
    padding: 4px 20px;
    font-size: 15px;
    font-family: $font-family-light, serif;
  }

  .refreshbutton {
    background-color: $primary;
    color: white;
    border-color: white;
    margin: 8px -25px 8px 25px;
    vertical-align: middle;
    padding: 4px 15px;
    font-size: 15px;
    font-family: $font-family-light, serif;
  }

  .title {
    font-family: $font-family-light, serif;
    font-weight: 600;
    font-size: 12pt;
    color: white;
    text-align: center;
    line-height: 50px;
  }
}
</style>
