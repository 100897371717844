<template>
  <transition-group name="modal">
    <div
      class="modal-mask"
      :key="'mask'"
      @click.once="$emit('toggleModal')"
    ></div>
    <div class="modal-container" :class="$mq" :key="'modal-1'">
      <div class="modal-body">
        <slot name="body">
          <pre>{{ warning }}</pre>
        </slot>
      </div>

      <div class="modal-footer">
        <slot name="footer">
          <button class="btn btn-novacair-light" @click="$emit('toggleModal')">
            Sluiten
          </button>
        </slot>
      </div>
    </div>
  </transition-group>
</template>

<script>
export default {
  props: ['warning'],
};
</script>

<style scoped lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-container.large {
  margin-top: 25px;
}

.modal-container {
  width: 80%;
  height: 66%;
  position: absolute;
  z-index: 60000000;
  left: 10%;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow-y: hidden;
  overflow-x: hidden;
}

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  font-size: 15px;
  color: $primary;
  font-family: $font-family-light;
}

.small.modal-container {
  width: 90%;
  left: 5%;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  height: calc(100% - 100px);
  overflow-y: auto;
}

.modal-default-button {
  float: right;
}

/*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
