import { getAnesthesiaTechniques, getAnesthesiaVideos } from '@/api';

/**
 * This is specifically used for stale resources, preventing unnecessary requests.
 */
export const resources = {
  anesthesiaTechniques: null,
  anesthesiaVideos: null,

  getAnesthesiaTechniques() {
    if (!this.anesthesiaTechniques) {
      this.anesthesiaTechniques = getAnesthesiaTechniques().then(
        (response) => response.data
      );
    }

    return this.anesthesiaTechniques;
  },

  getAnesthesiaVideos() {
    if (!this.anesthesiaVideos) {
      this.anesthesiaVideos = getAnesthesiaVideos().then(
        (response) => response.data
      );
    }

    return this.anesthesiaVideos;
  },
};
