<template>
  <div
    @click="clickOpenModal"
    class="locale-changer"
    :class="{ dark: dark, light: !dark }"
  >
    <div class="explanation">
      {{ $t('label') }}
    </div>
    <div class="language">
      {{ languages.localeMap[$i18n.locale] }}
    </div>
  </div>
</template>

<script>
import LocaleChangerModal from '@/components/LocaleChangerModal.vue';
import languages from '@/languages';

export default {
  name: 'locale-changer',
  props: ['toggleCustomModal', 'setLocale', 'dark', 'supportedLanguages'],

  data() {
    return {
      openModal: false,
      languages: languages,
    };
  },
  methods: {
    clickOpenModal() {
      this.toggleCustomModal(LocaleChangerModal, {
        supportedLanguages: this.supportedLanguages,
        setLocale: this.setLocale,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.locale-changer.dark {
  margin: 4px 0;
  .explanation {
    cursor: pointer;
    color: $primary;
  }
  .btn {
    color: $primary;
  }
}

.locale-changer.light {
  margin-top: 1px;

  .explanation {
    margin-top: 1px;
  }
}

.locale-changer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;

  .explanation {
    color: $primary;
    font-weight: bold;
  }
}

.language {
  color: $primary;
  text-decoration: underline;
}
</style>
